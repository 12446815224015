import styled from '@emotion/styled'
import tw from 'twin.macro'

export const TitleWrapper = styled.div`
  ${tw`
    overflow-hidden
    relative
  `}
`

export const TitleStyle = styled.div`
  ${tw`
    mt-10
    mx-auto
    max-w-screen-xl
    px-4
    sm:mt-12
    sm:px-6
    md:mt-16
    lg:mt-20
    xl:mt-28
  `}
`

export const TitleText = styled.div`
  ${tw`
    text-left
  `}
  h1 {
    ${tw`
      text-5xl
      tracking-tight
      leading-10
      font-bold
      text-purple-900
      lg:text-6xl
      lg:leading-none
    `}
  }
  p {
    ${tw`
      mt-8
      text-lg
      text-purple-300
      sm:text-xl
      md:text-xl
      md:max-w-3xl
    `}
  }
`
