import React from 'react'
import { Helmet } from 'react-helmet'
import ContentSection from '../components/content_sections'
import Layout from '../components/layout'
import SitemapCategory from '../components/sitemap/category'
import Title from '../components/sitemap/title'

const sitemapItems = {
  products: [
    {
      link_name: 'Passwordless User Account',
      link_path: '/authentication/',
    },
    // {
    //   link_name: 'Users Management',
    //   link_path: '/users-management/',
    // },
    // {
    //   link_name: 'UI Editor',
    //   link_path: '/ui-editor/',
    // },
    {
      link_name: 'Enterprise Connections',
      link_path: '/enterprise-connections/',
    },
    {
      link_name: 'SSO Admin Onboarding',
      link_path: '/sso-admin-onboarding/',
    },
    {
      link_name: 'SCIM',
      link_path: '/scim/',
    },
    {
      link_name: 'Pricing',
      link_path: '/pricing/',
    },
    // {
    //   link_name: 'Pro',
    //   link_path: '/pro/',
    // },
  ],
  sso: [
    {
      link_name: 'SAML SSO Connection',
      link_path: '/saml-sso/',
    },
    {
      link_name: 'Active Directory',
      link_path: '/active-directory/',
    },
    {
      link_name: 'Loyal VC’s perk with Cryptr',
      link_path: '/loyal-vc-perk-with-cryptr/',
    },
    {
      link_name: 'Conector’s perk with Cryptr',
      link_path: '/conector-perk-with-cryptr/',
    },
    {
      link_name: 'Startupbootcamp’s perk with Cryptr',
      link_path: '/startupbootcamp-perk-with-cryptr/',
    },
    {
      link_name: 'Kima’s perk with Cryptr',
      link_path: '/kima-perk-with-cryptr/',
    },
    {
      link_name: 'Maze’s perk with Cryptr',
      link_path: '/maze-perk-with-cryptr/',
    },
  ],
  developers: [
    {
      link_name: 'Developers Hub',
      link_path: '/developers/',
    },
  ],
  resources: [
    {
      link_name: 'Blog',
      link_path: '/blog/',
    },
  ],
  company: [
    {
      link_name: 'Investors',
      link_path: '/investors/',
    },
  ],
  legal: [
    {
      link_name: 'Privacy Policy',
      link_path: '/privacy-policy',
    },
  ],
}

const Sitemap = () => {
  return (
    <Layout helmetTitle="Sitemap">
      <Title title="Sitemap" />
      <ContentSection>
        <SitemapCategory category_name="Products" items={sitemapItems.products} />
        <SitemapCategory category_name="SSO" items={sitemapItems.sso} />
        {/* <SitemapCategory category_name="Developers" items={sitemapItems.developers} /> */}
        <SitemapCategory category_name="Resources" items={sitemapItems.resources} />
        <SitemapCategory category_name="Company" items={sitemapItems.company} />
        <SitemapCategory category_name="Legal" items={sitemapItems.legal} />
      </ContentSection>
    </Layout>
  )
}

export default Sitemap
