import styled from '@emotion/styled'
import tw from 'twin.macro'

export const SitemapCategoryBG = styled.section`
  ${tw`
    bg-white
  `}
`

export const SitemapCategoryFlex = styled.div`
  ${tw`
    pt-4
    border-t-2
    border-gray-200
    xl:flex
    xl:flex-wrap
    xl:w-full
    xl:items-start
  `}
  :not(:last-of-type) {
    ${tw`
      mb-24
    `}
  }
`

export const SitemapCategoryTitle = styled.div`
  ${tw`
    xl:w-2/6
  `}
  h4 {
    ${tw`
      text-purple-900
      text-base
      font-medium
      leading-6
    `}
  }
`

export const SitemapCategoryItems = styled.div`
  ${tw`
    mt-8
    md:mt-0
    xl:w-4/6
  `}
`

export const SitemapCategoryGridCols = styled.div`
  ${tw`
    md:grid
    md:grid-cols-2
    md:gap-8
    xl:mt-0
    xl:col-span-2
  `}
`

export const SitemapCategoryList = styled.div`
  ul {
    ${tw`
      mt-4
      space-y-4
    `}
  }
  a {
    ${tw`
      text-sm
      leading-6
      text-gray-500
      hover:text-gray-900
    `}
  }
`
