import React from 'react'
import { TitleStyle, TitleText, TitleWrapper } from './style'

type TitleProps = {
  title: string
  description?: string
}

const Title = ({ title, description }: TitleProps) => {
  return (
    <TitleWrapper>
      <TitleStyle>
        <TitleText>
          <h1>{title}</h1>
          <p>{description}</p>
        </TitleText>
      </TitleStyle>
    </TitleWrapper>
  )
}

export default Title
