import React from 'react'
import Container from '../elements/container'
import { Section } from './style'

const ContentSection = ({ children, className }: any) => {
  return (
    <Section className={className}>
      <Container>{children}</Container>
    </Section>
  )
}

export default ContentSection
