import { Link } from 'gatsby'
import React from 'react'
import {
  SitemapCategoryFlex,
  SitemapCategoryItems,
  SitemapCategoryGridCols,
  SitemapCategoryList,
  SitemapCategoryTitle,
} from './style'

const SitemapCategory = ({ category_name, items }: any) => {
  return (
    <SitemapCategoryFlex>
      <SitemapCategoryTitle>
        <h4>{category_name}</h4>
      </SitemapCategoryTitle>
      <SitemapCategoryItems>
        <SitemapCategoryGridCols>
          <SitemapCategoryList>
            <ul>
              {items
                .slice(0, 4)
                .map((item: { link_path: string; link_name: string }, index: any) => {
                  return (
                    <li key={index}>
                      <Link to={item.link_path}>{item.link_name}</Link>
                    </li>
                  )
                })}
            </ul>
          </SitemapCategoryList>
          <SitemapCategoryList>
            <ul>
              {items
                .slice(4, 8)
                .map((item: { link_path: string; link_name: string }, index: any) => {
                  return (
                    <li key={index}>
                      <Link to={item.link_path}>{item.link_name}</Link>
                    </li>
                  )
                })}
            </ul>
          </SitemapCategoryList>
        </SitemapCategoryGridCols>
      </SitemapCategoryItems>
    </SitemapCategoryFlex>
  )
}

export default SitemapCategory
