import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Section = styled.section`
  ${tw`
    py-20
    md:py-32
    overflow-hidden
    relative
    w-full
  `}
  &.home, &.pricing {
    ${tw`
      pt-0
    `}
  }
  &.dark-blue {
    ${tw`
      bg-blue-800
    `}
  }
  &.light-teal {
    ${tw`
      bg-teal-100
    `}
  }
  &.light-orange {
    ${tw`
      bg-orange-100
    `}
  }
`
